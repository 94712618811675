<template>
    <div class="table-box p-3 mb-3">
        <div class="comment-panel">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active w-100"
                            @click="changeSelector('discussion')"
                            id="comment-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#comment"
                            type="button"
                            role="tab"
                            aria-controls="comment"
                            aria-selected="true">
                        <font-awesome-icon icon="comments"/>
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                            class="nav-link"
                            id="usercard-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#usercard"
                            type="button"
                            role="tab"
                            aria-controls="usercard"
                            aria-selected="true"
                    >
                        <font-awesome-icon
                                :icon="['fas', 'id-card-alt']"
                        ></font-awesome-icon>
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                            class="nav-link"
                            id="attachment-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#attachment"
                            type="button"
                            role="tab"
                            aria-controls="attachment"
                            aria-selected="false"
                    >
                        <font-awesome-icon
                                :icon="['fas', 'paperclip']"
                        ></font-awesome-icon>
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link w-100"
                            @click="changeSelector('')"
                            id="history-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#history"
                            type="button" role="tab"
                            aria-controls="history"
                            aria-selected="false">
                        <font-awesome-icon icon="history"/>
                    </button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">

                <div class="tab-pane fade show active"
                     id="comment" role="tabpanel"
                     aria-labelledby="comment-tab">
                    <div v-if="dataList.commentsData.length < 1"
                         class="border-bottom mb-2 text-center sm-text">
                        {{ translations?.no_more_data }} :)
                    </div>
                    <div v-else class="comment-ctl mb-3">
                        <div v-for="(item, key) in dataList.commentsData"
                             :key="`comment-item-${key}`"
                             class="border-bottom pb-2 mb-2">
                            <div class="comment-text">
                                <div class="d-flex align-items-center">
                                    <div class="profile-avatar me-2">
                                        <img :src="item.initiated_by ? item.initiated_by :
                                        require('@/assets/img/profile-avatar.png')"
                                             alt="" class="img-fluid"/>
                                    </div>
                                    <p>{{ item.content }}</p>
                                </div>
                            </div>
                            <div class="comment-date text-end sm-text">
                                {{ item.initiated_at }}
                            </div>
                        </div>
                    </div>

                    
                    <!--comment box-->
                    <div>
                        <div v-if="contentPageInfo.canEdit"
                             class="input-group">
                            <input type="text"
                                   class="form-control"
                                   placeholder="Type Something"
                                   v-model="dataList.comment"
                                   @keydown.enter.prevent="storeDiscussionBoard"
                                   name="comment">
                            <button class="btn btn-outline-secondary"
                                    @click="storeDiscussionBoard"
                                    :disabled="dataList.comment.length < 1"
                                    type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                                    <path
                                        d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                                </svg>
                            </button>
                        </div>
                    </div>

                </div>

                <div
                    class="tab-pane fade"
                    id="usercard"
                    role="tabpanel"
                    aria-labelledby="usercard-tab"
                >
                    <div class="comment-ctl" v-if="contentPageInfo.content_item.explanation">
                        <div class="border-bottom mb-2 text-center sm-text" v-html="contentPageInfo.content_item.explanation">
                            
                        </div>
                    </div>
                </div>
                <div
                    class="tab-pane fade"
                    id="attachment"
                    role="tabpanel"
                    aria-labelledby="attachment-tab"
                >
                    <div class="comment-ctl" v-if="contentPageInfo.content_item.links">
                        <div class="border-bottom mb-2 text-center sm-text" v-html="contentPageInfo.content_item.links">
                            
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="history" role="tabpanel"
                     aria-labelledby="history-tab">
                    <div v-if="dataList.historyData.length < 1"
                         class="border-bottom mb-2 text-center sm-text">
                         {{ translations?.no_more_data }} :)
                    </div>
                    <div v-else class="comment-ctl">
                        <div v-for="(item, key) in dataList.historyData"
                             :key="`history-item-${key}`"
                             class="border-bottom pb-2 mb-2">
                            <div class="comment-text">
                                <div class="d-flex align-items-center">
                                    <div class="profile-avatar me-2">
                                        <img :src="item.initiated_by ? item.initiated_by :
                                        require('@/assets/img/profile-avatar.png')"
                                             alt="" class="img-fluid"/>
                                    </div>
                                    <p v-html="item.content"></p>
                                </div>
                            </div>
                            <div class="comment-date text-end sm-text">
                                {{ item.initiated_at }}
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
    </div>
</template>

<script>
import {inject, reactive, watch} from "vue";
import {useStore} from "vuex";
import HelperFunction from "@/common/helpers";

export default {
    name: "TemplateDiscussion",
    props: {
        id: {
            type: [Number, String],
            required: false,
        },
        contentPageInfo: {
            type: Object,
            required: false
        },
        translations: {
            type: Object,
            required: false
        }
    },
    setup(props) {
        const toast = inject('toast')
        const {showToast} = HelperFunction();
        const store = useStore();
        let dataList = reactive({
            historyData: [],
            commentsData: [],
            selector: 'discussion',
            comment: '',
            commentType: 'text',
        });

        /** watch **/
        watch(
            () => props.id,
            (value) => {
                if (value) {
                    getDiscussionBoard();
                }
            },
            {immediate: true}
        );

        /** watch end**/

        /** Methods**/
        async function getDiscussionBoard() {
            let payload = {
                selector: dataList.selector,
                initiateable_id: props.id,
                page: 1
            }
            await store.dispatch("getProjectDiscussionBoard", payload)
                .then((resp) => {
                    const data = resp.data.data;
                    if (dataList.selector === 'discussion') {
                        dataList.commentsData = data;
                    } else {
                        dataList.historyData = data;
                    }
                    // showToast(resp);
                })
                .catch((error) => {
                    showToast(error);
                });
        }

        async function storeDiscussionBoard() {
            if (dataList.comment.length > 0) {
                let payload = {
                    selector: dataList.selector,
                    template_id: props.id,
                    type: dataList.commentType,
                    massage: dataList.comment
                }
                await store.dispatch("storeProjectDiscussionBoard", payload)
                    .then(res => {
                        dataList.comment = '';
                        getDiscussionBoard();
                        // showToast(res);
                    })
                    .catch(error => {
                        showToast(error);
                    })
            } else {
                toast.warning('Comment box is empty!');
            }
        }

        const changeSelector = (value = '') => {
            dataList.selector = value;
            getDiscussionBoard();
        }
        /** Methods end**/

        return {
            dataList,
            changeSelector,
            storeDiscussionBoard
        }
    }
}
</script>

