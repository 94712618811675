<template>
  <section :class="$route.name == 'project-task' ? 'main-page pt-3' : ''">
    <div class="row" :class="$route.name == 'project-task' ? 'ms-3 me-3' : ''">
      <div
        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3"
      >
        <Form
          ref="resetForm"
          @submit="formAction(data.inputFormData)"
          v-slot="{ errors }"
          :validation-schema="schema"
        >
          <div class="table-box p-3 mb-3">
            <div class="new-template-form">
              <div class="form-group row mb-3">
                <div class="col-sm-12">
                  <editor
                    :api-key="editorKey"
                    id="editor"
                    :init="{
                      element_format: 'html',
                      height: 400,
                      menubar: 'insert',
                      convert_urls: false,
                      relative_urls: false,
                      image_caption: true,
                      image_advtab: true,
                      min_height: 200,
                      max_height: 650,
                      valid_elements: '+*[*]',
                      pagebreak_separator:
                        '<br style=\'page-break-before:always\'/>',
                      nonbreaking_force_tab: true,
                      content_css: [
                        '/css/tinymce-editor.css?v=' + Math.random(),
                      ],
                      body_id: 'wrapper',
                      plugins: [
                        'autoresize advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen pagebreak',
                        'insertdatetime media table paste code codesample help wordcount preview hr',
                      ],
                      external_plugins: {
                        autocomplete:
                          '/js/tinymce/plugins/autocomplete/plugin.js',
                        WYImageManager:
                          '/js/tinymce/plugins/wy_image_manager/plugin.js',
                        glossary: '/js/tinymce/plugins/glossary/plugin.js',
                        document: '/js/tinymce/plugins/document/plugin.js',
                        ruler: '/js/tinymce/plugins/ruler/plugin.js',
                      },
                      toolbar:
                        'fullscreen | undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | WYImageManager | \
                        bullist numlist outdent indent | link s | table | glossary | document | removeformat | pagebreak | help',
                    }"
                    v-model="data.inputFormData.content"
                  />
                </div>
                <div class="form-group row mt-3">
                  <div class="col-sm-offset-2 col-sm-10">
                    <button
                      type="submit"
                      class="btn btn-green me-2"
                      @click="savePreview"
                    >
                      Save
                    </button>
                    <button @click="goBack" class="btn btn-secondary">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </section>
</template>

<script>
import HelperFunction from "@/common/helpers";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, onMounted, reactive, inject } from "vue";
import * as Yup from "yup";
import { Field, Form } from "vee-validate";
import ApproverGroup from "@/common/components/approver-group";
import InviteUser from "@/common/components/invite-user/Index";
import ApproverButtonGroup from "@/common/components/button-groups";
import ProjectDiscussion from "@/views/auth/project/content-item/ProjectDiscussion";
import BottomFooter from "@/views/layouts/auth/common/BottomFooter";

export default {
  name: "ProjectTask",
  components: {
    Field,
    Form,
    ApproverGroup,
    InviteUser,
    ApproverButtonGroup,
    ProjectDiscussion,
    BottomFooter,
  },
  setup() {
    const toast = inject("toast");
    const {
      data,
      editFormData,
      storeFormData,
      resetForm,
      formPermissionResolver,
    } = HelperFunction();
    const route = useRoute();
    const router = useRouter();
    const content_id = route.params.content_id
      ? route.params.content_id
      : route.params.id;
    const store = useStore();
    const profile = store.getters.getProfile;
    const company = store.getters.getSelectedBoard;
    const tinyParm = {
      company_id: company.id,
      project_id: route.params.id,
      item_id: route.params.content_id,
      document_id: route.params.doc,
    };
    const editorKey = process.env.VUE_APP_EDITOR_KEY;

    data.setURL =
      vueConfig.Project.ProjectEndPoint +
      "/documents/preview/" +
      tinyParm.document_id;

    onMounted(() => {
      onMountedCall();
    });

    async function onMountedCall() {
      await editFormData({ url: data.setURL });
    }

    /** watch & computed **/
    const menuItems = computed(() => store.getters.getMenuItems);
    let selectedMenu = menuItems.value.find((item) => {
      return item.route_link.toLowerCase() == "projects";
    });
    if (selectedMenu) {
      store.dispatch("changeMenu", selectedMenu);
    }

    formPermissionResolver("contents", false, ["update"]);
    /** watch end **/

    const formAction = (inputFormData) => {
      storeFormData({ url: data.setURL }, inputFormData);
    };
    //VALIDATION
    const schema = Yup.object().shape({
      content: Yup.string().required(),
    });

    const siteUrl = (url) => {
      return process.env.VUE_APP_BACKEND_URL + url;
    };

    const savePreview = async function () {
      data.inputFormData["_method"] = "PUT";

      await storeFormData({ url: data.setURL }, data.inputFormData).then(() => {
        goBack();
      });
    };

    const goBack = () => {
      router.push({
        name: "document-view",
        params: {
          id: tinyParm.project_id,
          doc: tinyParm.document_id,
        },
      });
    };

    return {
      data,
      editorKey,
      formAction,
      schema,
      tinyParm,
      siteUrl,
      profile,
      resetForm,
      content_id,
      savePreview,
      goBack,
    };
  },
};
</script>
